import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import moment from 'moment';

export default class ReceiptPdf {
  static print(content) {
    let fontMercant = Helper.MerchantFontBase64();
    let fontNamu = Helper.NamuFontBase64();
    pdfMake.vfs = {
      ...pdfFonts.pdfMake.vfs,
      'Merchant-Regular.ttf': fontMercant?.['Merchant-Regular.ttf'],
      'Merchant-Bold.ttf': fontMercant?.['Merchant-Bold.ttf'],
      'Merchant-Italic.ttf': fontMercant?.['Merchant-Italic.ttf'],
      'Merchant-BoldItalic.ttf': fontMercant?.['Merchant-BoldItalic.ttf'],
      'NamuGothic-Regular.ttf': fontNamu?.['NamuGothic-Regular.ttf'],
      'NamuGothic-Bold.ttf': fontNamu?.['NamuGothic-Bold.ttf'],
      'NamuGothic-Regular.ttf': fontNamu?.['NamuGothic-Regular.ttf'],
      'NamuGothic-Bold.ttf': fontNamu?.['NamuGothic-Bold.ttf'],
    };
    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
      TimesNewRoman: {
        normal: 'Times-Regular.ttf',
        bold: 'Times-Bold.ttf',
        italics: 'Times-Italic.ttf',
        bolditalics: 'Times-BoldItalic.ttf',
      },
      MerchantCopy: {
        normal: 'Merchant-Regular.ttf',
        bold: 'Merchant-Bold.ttf',
        italics: 'Merchant-Italic.ttf',
        bolditalics: 'Merchant-BoldItalic.ttf',
      },
      NamuGothic: {
        normal: 'NamuGothic-Regular.ttf',
        bold: 'NamuGothic-Bold.ttf',
        italics: 'NamuGothic-Regular.ttf',
        bolditalics: 'NamuGothic-Bold.ttf',
      },
    };

    const docDefinition = {
      pageSize: {
        height: 'auto',
        width: 305,
      },
      pageMargins: [5, 20, 5, 20],
      pageOrientation: 'potrait',
      content: content,
      styles: {
        header: {
          fontSize: 24,
          bold: false,
          margin: [0, 0, 0, 10],
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          fontSize: 21,
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-10, 5, 0, -10],
        },
        tableSpace: {
          fontSize: 21,
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 21,
          color: 'black',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
        font: 'MerchantCopy',
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBuffer(function (buffer) {
      const dataUrl = URL.createObjectURL(
        new Blob([buffer], {
          type: 'application/pdf',
        })
      );
      console.log(dataUrl);
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.right = '0';
      iframe.style.bottom = '0';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = '0';
      iframe.src = dataUrl;
      iframe.width = '300px';
      iframe.height = '300px';
      document.body.appendChild(iframe);

      iframe.contentWindow.print();
    });
  }

  static getContentBody(data) {
    let contentItem = [];

    data?.forEach((item) => {
      contentItem.push([
        { text: item?.name, margin: [0, 0, 0, 2], border: [false, false, false, false], colSpan: 2 },
        {},
      ]);
      item?.item_details?.forEach((unit) => {
        contentItem.push([
          {
            text: unit?.qty + 'x ' + unit?.name,
            margin: [10, 0, 0, 0],
            border: [false, false, false, false],
            colSpan: 2,
          },
          {},
        ]);
      });
      contentItem.push([
        {
          text:
            item?.qty +
            'x ' +
            'Rp ' +
            Helper.formatMoney(
              item?.item_details?.length > 0
                ? +item?.price + +item?.voucher_amount
                : +item?.unit_price + +item?.voucher_amount
            ),
          margin: [2, 0, -10, 2],
          border: [false, false, false, false],
        },
        {
          text: 'Rp ' + Helper.formatMoney(+item?.price + +item?.voucher_amount),
          alignment: 'right',
          margin: [-50, 0, 0, 2],
          border: [false, false, false, false],
        },
      ]);
      contentItem.push([
        {
          text: +item?.voucher_amount > 0 ? 'Diskon' : '',
          margin: [2, 0, -10, 2],
          border: [false, false, false, false],
        },
        {
          text: +item?.voucher_amount > 0 ? '(-Rp ' + Helper.formatMoney(item?.voucher_amount) + ')' : '',
          alignment: 'right',
          margin: [-50, 0, 0, 2],
          border: [false, false, false, false],
        },
      ]);
    });

    return {
      content_body: contentItem,
    };
  }

  static checkPaymentMethod(data) {
    let contentItem = [];

    if (data?.booking?.booking_type == 'b2c') {
      contentItem.push(
        [
          {
            text: 'Total',
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
          },
          {
            text: 'Rp ' + Helper.formatMoney(data?.bill?.amount ?? 0),
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            alignment: 'right',
          },
        ],
        [
          {
            text: 'Pembulatan',
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
          },
          {
            text: 'Rp ' + Helper.formatMoney(data?.bill?.rounding ?? 0),
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            alignment: 'right',
          },
        ],
        [
          { text: '', border: [false, false, false, true], margin: [0, 0, 0, 0] },
          { text: '', border: [false, false, false, true], margin: [0, 0, 0, 0] },
        ],
        [
          {
            text: 'Total Bayar',
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            fontSize: 25,
          },
          {
            text: 'Rp ' + Helper.formatMoney(data?.bill?.grand_total ?? 0),
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            alignment: 'right',
            fontSize: 25,
          },
        ]
      );
    } else {
      contentItem.push([
        {
          text: 'Total Bayar',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: 'Rp ' + Helper.formatMoney(data?.bill?.amount ?? 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ]);
    }

    data?.payment_methods?.forEach((item, index) => {
      contentItem.push([
        {
          text: item?.value ? 'Pembayaran ' + (index + 1) + ' - ' + item?.value : '-',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: 'Rp ' + Helper.formatMoney(item?.amount ?? 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ]);
    });

    if (data?.bill?.return_amount > 0) {
      contentItem.push([
        {
          text: 'Kembali',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: 'Rp ' + Helper.formatMoney(data?.bill?.return_amount ?? 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ]);
    }

    return {
      content_body: contentItem,
    };
  }

  static create(data) {
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const splitTime = data?.booking_time ? data?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = data?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = data?.user_car?.car_details?.car_model?.brand?.name
      ? data?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += data?.user_car?.car_details?.car_model?.model_name
      ? data?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += data?.user_car?.car_details?.variant ? data?.user_car?.car_details?.variant : '';
    const dataCalculate = this.getContentBody(data?.items);
    const dataPaymentMethod = this.checkPaymentMethod(data);
    let bodySummary = [];
    bodySummary.push(
      [
        {
          text: 'Sub Total',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: 'Rp ' + Helper.formatMoney(data?.bill?.sub_amount ? +data?.bill?.sub_amount : 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ],
      [
        {
          text: 'Kode Promo',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: '- Rp ' + Helper.formatMoney(data?.discount?.amount ?? 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ],
      [
        {
          text: 'Otopoints',
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          text: '- Rp ' + Helper.formatMoney(data?.otopoints?.point_spent ?? 0),
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
          alignment: 'right',
        },
      ]
    );

    let totalBills = [];
    totalBills.push([
      {
        text: 'Total Bayar',
        bold: true,
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
      },
      {
        text: 'Rp ' + Helper.formatMoney(data?.bill?.amount ?? 0),
        bold: true,
        italics: true,
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
        alignment: 'right',
      },
    ]);

    let content = [
      {
        image: Helper.logoBlackBase64(),
        alignment: 'center',
        width: 150,
        margin: [0, 0, 0, 20],
      },
      { text: data?.workshop?.name, style: 'header', alignment: 'center', fontSize: 22 },
      {
        text: data?.workshop?.address?.street_address,
        style: 'header',
        alignment: 'center',
        bold: false,
        fontSize: 22,
        margin: [10, 0, 10, 5],
      },
      {
        text: data?.workshop?.address?.city,
        style: 'header',
        alignment: 'center',
        bold: false,
        fontSize: 22,
        margin: [0, 5, 0, 10],
      },
      {
        style: 'tableContent',
        alignment: 'left',
        table: {
          widths: [80, 5, 180],
          body: [
            [
              {
                text: 'Kode Booking',
                margin: [0, 0, 0, -5],
                border: [false, false, false, false],
              },
              { text: ':', margin: [-5, 0, 0, -2], border: [false, false, false, false] },
              {
                text: data?.booking?.booking_code ?? '-',
                margin: [-10, 0, 0, -5],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Tanggal',
                margin: [0, 0, 0, -5],
                border: [false, false, false, false],
              },
              { text: ':', margin: [-5, 0, 0, -2], border: [false, false, false, false] },
              {
                text: moment(data?.booking?.booking_datetime).isValid()
                  ? moment(data?.booking?.booking_datetime).format('DD-MM-YYYY HH:mm') + ' (GMT+7)'
                  : '-',
                margin: [-10, 0, 0, -5],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Kasir',
                margin: [0, 0, 0, -5],
                border: [false, false, false, false],
              },
              { text: ':', margin: [-5, 0, 0, -2], border: [false, false, false, false] },
              {
                text: data?.officer ?? '-',
                margin: [-10, 0, 0, -5],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Pelanggan',
                margin: [0, 0, 0, -5],
                border: [false, false, false, false],
              },
              { text: ':', margin: [-5, 0, 0, -2], border: [false, false, false, false] },
              {
                text: (data?.user_car?.license_plate?.replace(/ +/g, '') ?? '-') + '/' + (data?.customer?.name ?? '-'),
                margin: [-10, 0, 0, -5],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Jenis Kendaraan',
                margin: [0, 0, 0, -5],
                border: [false, false, false, false],
              },
              { text: ':', margin: [-5, 0, 0, -2], border: [false, false, false, false] },
              {
                text: carType ?? '-',
                margin: [-10, 0, 0, -5],
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [285],
          body: [
            [{ text: '', border: [false, false, false, true], margin: [0, 30, 0, 0] }],
            [{ text: '', border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineWidth: function (i) {
            return 0.5;
          },
        },
      },
      { text: 'Detail Pesanan', style: 'header', alignment: 'center', fontSize: 21, margin: [0, 0, 0, 0] },
      {
        style: 'tableSpace',
        table: {
          widths: [285],
          body: [
            [{ text: '', border: [false, false, false, true], margin: [0, 5, 0, 0] }],
            [{ text: '', border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineWidth: function (i) {
            return 0.5;
          },
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [100, 180],
          body: dataCalculate?.content_body,
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [285],
          body: [
            [{ text: '', border: [false, false, false, true], margin: [0, 10, 0, 0] }],
            [{ text: '', border: [false, false, false, false] }],
            [{ text: '', border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineWidth: function (i) {
            return 0.5;
          },
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [100, 180],
          body: bodySummary,
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [285],
          body: [
            [{ text: '', border: [false, false, false, false] }],
            [{ text: '', border: [false, false, false, true], margin: [0, 0, 0, 0] }],
            [{ text: '', border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineWidth: function (i) {
            return 0.5;
          },
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [100, 180],
          body: dataPaymentMethod?.content_body,
        },
      },
      {
        text: 'Harga sudah termasuk PPN',
        margin: [30, 30, 5, 0],
        alignment: 'center',
        border: [false, false, false, false],
        fontSize: 18,
      },
      {
        text: 'Dapatkan diskon 10% pada kunjungan Anda berikutnya dengan menunjukan nota ini',
        margin: [5, 10, 5, 0],
        alignment: 'center',
        border: [false, false, false, false],
        fontSize: 18,
      },
      {
        text: 'Terima Kasih',
        margin: [0, 30, 0, 0],
        alignment: 'center',
        border: [false, false, false, false],
        fontSize: 21,
      },
    ];

    if (data?.workshop?.instagram) {
      content.push({
        text: 'IG: ' + data?.workshop?.instagram,
        margin: [0, 10, 0, 0],
        alignment: 'center',
        border: [false, false, false, false],
        fontSize: 21,
      });
    }

    if (data?.workshop?.phone) {
      content.push({
        text: 'WA: ' + data?.workshop?.phone,
        margin: [0, 10, 0, 0],
        alignment: 'center',
        border: [false, false, false, false],
        fontSize: 21,
      });
    }

    content.push({
      text: '----------------------------',
      margin: [0, 10, 0, 0],
      alignment: 'center',
      border: [false, false, false, false],
      fontSize: 22,
    });

    this.print(content);
  }
}
